import React from 'react';
import { StrapiMenu } from '../../api/menu';
import { Menu } from '@/components/menu';
import { StrapiFooter } from '../../api/footer';
import { Footer } from '@/components/footer';
import { Section0 } from '@/components/sections/security-vulnerability-disclosure/Section0';
import { Section1 } from '@/components/sections/security-vulnerability-disclosure/Section1';
import { Section4 } from '@/components/sections/service-detail/Section4';
import { StrapiSecurityVulnerabilityDisclosure } from '../../api/security-vulnerability-disclosure';
import { Head as HeadComponent } from '@/components/head';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Container } from '@mui/material';
import { MENU_HEIGHT } from '@/constants';
import getGatsbyImageData from '@/utils/getGatsbyImageData';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function SecurityVulnerabilityDisclosure({
  pageContext: {
    strapiMenu,
    strapiFooter,
    strapiSecurityVulnerabilityDisclosure: {
      metadata,
      section0,
      section1,
      section2,
      fullwidthimage,
    },
  },
}: {
  pageContext: {
    strapiMenu: StrapiMenu;
    strapiFooter: StrapiFooter;
    strapiSecurityVulnerabilityDisclosure:
    StrapiSecurityVulnerabilityDisclosure;
  };
}) {
  const headMeta = {
    title: 'Treeline Interactive',
    meta: {
      MetaTitle:
        'Mobile App, Web, and Internet of Things Development Company | Treeline Interactive',
      MetaDescription:
        'Treeline Interactive is a custom software and hardware development company, focused on the Internet of Things, Android development, iPad and iPhone development.',
      MetaKeywords:
        'mobile app development, mobile application development, android app development san diego, mobile app development san diego, mobile application development san diego, ios developer san diego, ios development san diego',
    },
  };

  const imageData = getGatsbyImageData(fullwidthimage.data, {
    layout: 'fullWidth',
  });

  return (
    <>
      <HeadComponent
        defaultTitle={headMeta.title}
        defaultMeta={headMeta.meta}
        metadata={metadata}
        image={
          metadata?.OGImage?.data?.attributes.url ||
          strapiMenu.logo.data.attributes.url
        }
      />
      <ParallaxProvider>
        <Menu strapiMenu={strapiMenu} />
        <Container
          sx={{
            mt: `${MENU_HEIGHT}px`,
          }}
          maxWidth={false}
          disableGutters
        >
          <Section0 strapiSection={section0} />
          {imageData && (
            <GatsbyImage
              image={imageData}
              alt={fullwidthimage.data.attributes.alternativeText || ''}
            />
          )}
          <Section1 strapiSection={section1} />
          <Section4 strapiSection={section2} />
        </Container>
        <Footer strapiFooter={strapiFooter} />
      </ParallaxProvider>
    </>
  );
}
