import React, { FC } from 'react';
import { Container } from '@mui/material';
import { SecurityVulnerabilityDisclosureSection0 } from '../../../../api/security-vulnerability-disclosure';
import { MainColumn } from '@/components/main-column';
import { MarkdownContent } from '@/components/markdown-content';

type ISecurityVulnerabilityDisclosureSection0 = {
  strapiSection: SecurityVulnerabilityDisclosureSection0;
};

export const Section0: FC<ISecurityVulnerabilityDisclosureSection0> = ({
  strapiSection,
}) => (
  <Container
    maxWidth={false}
    disableGutters
    sx={{
      backgroundColor: 'greyscale.white',
    }}
  >
    <MainColumn
      sx={{
        pt: { xs: 8, sm: 10 },
        mb: { xs: 5, sm: 9 },
      }}
    >
      <MarkdownContent
        content={strapiSection?.paragraph}
        sx={{
          'h1, h2, h3, h4, h5, h6': {
            mb: 4,
          },
        }}
      />
    </MainColumn>
  </Container>
);
